function closeProject(fade) {

    if(fade==true) {

        $('.project_detail').fadeOut(500,function() {

            $('.project_detail').removeClass('index');
            $('.project_detail').removeClass('visible');
            $('body').removeClass('noscroll');
            $('.why_logo').data('action','projects');
            $('.project_detail').css('display','');
            $('.project_detail').css('opacity','');

        })


    } else {

        $('.project_detail').removeClass('index');
        $('.project_detail').removeClass('visible');
        $('body').removeClass('noscroll');
        $('.why_logo').data('action','projects');
        $('body').removeClass('noscroll');
        $('.project_detail').css('opacity',0);

    }

    // remove content to project
    $('.project_detail').html('');

}

/*function scrollProject() {

    var wWidth = $(window).width(),
        picWIdth = $('.last > img').width(),
        left = $('.last').position().left,
        paddingLeft = wWidth-picWIdth;

    if(left<=wWidth) {
        var opacity = 1 - ((wWidth-left) / picWIdth);
        $('.project_detail').css('opacity',opacity);
        if(opacity<0.05) {
            closeProject(false);
        }
    } else if(left>wWidth) {
        $('.project_detail').css('opacity',1);
    }

}*/

function adjustRowHeight() {

    var wWidth = $(window).width(),
        columns = 20,
        rowHeight = wWidth/columns;

    $('.row').css('height',rowHeight+'px');

}

function adjustMobileRowHeight() {

    var wWidth = $(window).width(),
        columns = 16,
        rowHeight = wWidth/columns;

    $('.row').css('height',rowHeight+'px');

}

$(window).on('load', function() {
    $('#preloader').delay(350).fadeOut(500).delay(1000).remove();
    /*$("video").each(function(){
        $(this).get(0).play();
    });*/
})

$.fn.isInViewport = function() {

    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

/*window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}*/

function responsiveIntroVideo() {

    // Window width
    var wWidth          = $(window).width(),
        videoTagOpen1   = '<video id="video_intro" preload="auto" muted autoplay playsinline loop="loop" class="fullscreen ',
        videoTagOpen2   = '">',
        videoTagClose   = '</video>';

    // Set video based on native dimensions
    if($(window).width()<768) {
        $('.videowhy').html(videoTagOpen1+'mobile'+videoTagOpen2+'<source src="videos/intro-mobile.mp4" type="video/mp4">'+videoTagClose); // <source src="videos/intro-mobile.webm" type="video/webm">
    } else {
        $('.videowhy').html(videoTagOpen1+'desktop'+videoTagOpen2+'<source src="videos/intro-desktop.mp4" type="video/mp4">'+videoTagClose); // <source src="videos/intro-desktop.webm" type="video/webm">
    }

    // Resize window
    $(window).resize(function() {
        if($(window).width()<768 && wWidth>=768) {
            $('.videowhy').html(videoTagOpen1+'mobile'+videoTagOpen2+'<source src="videos/intro-mobile.mp4" type="video/mp4">'+videoTagClose); // <source src="videos/intro-mobile.webm" type="video/webm">
            wWidth = $(window).width();
        } else if($(window).width()>=768 && wWidth<768) {
            $('.videowhy').html(videoTagOpen1+'desktop'+videoTagOpen2+'<source src="videos/intro-desktop.mp4" type="video/mp4">'+videoTagClose); // <source src="videos/intro-desktop.webm" type="video/webm">
            wWidth = $(window).width();
        }
    });

}

$(document).ready(function(e) {

    var basehref        = window.location.origin;

    // Duplicate initial_10000
    var galleryDesktop  = $('.initial.desktop').html(),
        galleryMobile   = $('.initial.mobile').html(),
        loop            = Number($('.initial.desktop').data('loop')),
        loopCurrent     = Number(loop);

    // Duplicate project initial data
    var project_data        = '',
        projectLoop         = '',
        projectLoopCurrent  = '';

    responsiveIntroVideo();

    $(window).on('scroll', function(event){

        $('video').each(function(){
            if($(this).is(":in-viewport")) {
                $(this)[0].play();
            } else {
                $(this)[0].pause();
            }
        })

        // Add gallery each time vertical scroll hit top or bottom
        if($('body').hasClass('infinite')) {

            var documentWindow  = $(document).height(),
                windowHeight    = $(window).height(),
                projectsTop     = $(document).scrollTop(),
                loopFirst       = $('#projects .gallery:first-child').data('loop'),
                loopLast        = $('#projects .gallery:last-child').data('loop');

            // Mobile
            if($(window).width()<768) {

                if(projectsTop<=350 || projectsTop<=0) {

                    $('#projects').prepend('<div class="gallery mobile" data-loop="'+Number(loopFirst-1)+'">'+galleryMobile+'</div>');

                    $('.gallery.mobile').each(function() {
                        var dataLoop = $(this).data('loop');
                        if(dataLoop>=(loopCurrent+2)) {
                            //$(this).remove();
                        }
                    })
                    adjustRowHeight();
                    adjustMobileRowHeight();
                    loopCurrent = Number(loopFirst-1);

                } else if(projectsTop>=((documentWindow-windowHeight)-350)) {

                    $('#projects').append('<div class="gallery mobile" data-loop="'+Number(loopLast+1)+'">'+galleryMobile+'</div>');

                    $('.gallery.mobile').each(function() {
                        var dataLoop = $(this).data('loop');
                        if(dataLoop<=(loopCurrent-2)) {
                            //$(this).remove();
                        }
                    })
                    adjustRowHeight();
                    adjustMobileRowHeight();
                    loopCurrent = Number(loopLast+1);

                }

                /*if(projectsTop<=250 || projectsTop<=0) {

                    $('#projects').prepend('<div class="gallery mobile" data-loop="'+Number(loopFirst-2)+'">'+galleryMobile+'</div>');
                    $('#projects').prepend('<div class="gallery mobile" data-loop="'+Number(loopFirst-1)+'">'+galleryMobile+'</div>');
                    $('#projects').append('<div class="gallery mobile" data-loop="'+Number(loopFirst+1)+'">'+galleryMobile+'</div>');

                    $('.gallery.mobile').each(function() {
                        var dataLoop = $(this).data('loop');
                        if(dataLoop>=(loopCurrent+1)) {
                            $(this).remove();
                        }
                    })
                    adjustRowHeight();
                    adjustMobileRowHeight();
                    loopCurrent = Number(loopFirst-1);

                } else if(projectsTop>=((documentWindow-windowHeight)-250)) {

                    $('#projects').prepend('<div class="gallery mobile" data-loop="'+Number(loopLast-2)+'">'+galleryMobile+'</div>');
                    $('#projects').prepend('<div class="gallery mobile" data-loop="'+Number(loopLast-1)+'">'+galleryMobile+'</div>');
                    $('#projects').append('<div class="gallery mobile" data-loop="'+Number(loopLast+1)+'">'+galleryMobile+'</div>');

                    $('.gallery.mobile').each(function() {
                        var dataLoop = $(this).data('loop');
                        if(dataLoop<=(loopCurrent-1)) {
                            $(this).remove();
                        }
                    })
                    adjustRowHeight();
                    adjustMobileRowHeight();
                    loopCurrent = Number(loopLast+1);

                }*/

            // Desktop
            } else {

                if(projectsTop<=350 || projectsTop<=0) {

                    $('#projects').prepend('<div class="gallery desktop" data-loop="'+Number(loopFirst-1)+'">'+galleryDesktop+'</div>');

                    $('.gallery.desktop').each(function() {
                        var dataLoop = $(this).data('loop');
                        if(dataLoop>=(loopCurrent+2)) {
                            //$(this).remove();
                        }
                    })
                    adjustRowHeight();
                    adjustMobileRowHeight();
                    loopCurrent = Number(loopFirst-1);

                } else if(projectsTop>=((documentWindow-windowHeight)-350)) {

                    $('#projects').append('<div class="gallery desktop" data-loop="'+Number(loopLast+1)+'">'+galleryDesktop+'</div>');

                    $('.gallery.desktop').each(function() {
                        var dataLoop = $(this).data('loop');
                        if(dataLoop<=(loopCurrent-2)) {
                            //$(this).remove();
                        }
                    })
                    adjustRowHeight();
                    adjustMobileRowHeight();
                    loopCurrent = Number(loopLast+1);

                }

            }

        }

    })

    // Adjust size a page load and on window resize
    adjustRowHeight();
    adjustMobileRowHeight();
    $(window).resize(function() {
        adjustRowHeight();
        adjustMobileRowHeight();
    });

    // show project
    $(document).on('click', '.show_project', function() {

        var projectId = $(this).data('project-id');

        // remove content to project
        $('.project_detail').html('');

        if($(window).width()<768) {
            var url = basehref+'/project-mobile/'+projectId;
        } else {
            var url = basehref+'/project-desktop/'+projectId;
        }
        $.ajax({
            type: "GET",
            url: url,
            async: false,
            success : function(page_content) {

                // add content to project
                $('.project_detail').html(page_content);

                // Get current window size
                var wWidth = $(window).width();

                // Set copy width 50% of current window size
                $('.copy > .content').css({'width':wWidth/2+'px'});
                $('.project_detail').fadeIn(100,function() {
                    $(this).addClass('index').addClass('visible');
                });
                $('.project_detail').scrollLeft(0);
                $('body').addClass('noscroll');

                $('.mouse').remove();

                // Change data action of logo
                $('.why_logo').data('action','close-project');

            }
        });

    })

    document.getElementById('project_detail').addEventListener('wheel', function(event) {
        if($(".copy:hover").length == 0){
            swiper.mousewheel.enable();
            document.getElementById('project_detail').scrollLeft += event.deltaY;
        } else {
            swiper.mousewheel.disable();
        }
    });

    // Click on WHY LOGO
    $('.why_logo').click(function() {

        var action = $(this).data('action');

        // Scroll to center of gallery
        if(action=='projects') {

            // Go to Center of Container
            if($(window).width()<768) {
                var gotoY = Number($('#targetCenter').scrollTop()+200);
                window.scrollTo(0, gotoY);
            } else {
                $('.container').scrollTop(100);
            }

            $('.videowhy').fadeOut(500,function() {
                $(this).remove();
                $('body').removeClass('noscroll');
                $('body').addClass('infinite');
            });

        // Close open projet
        } else if(action=='close-project') {

            closeProject(true);

        }

    })

    var orejimeConfig = {

        // Optional. You can customize the ID of the <div> that Orejime will create when starting up.
        // The generated <div> will be inserted at the beginning of the <body>.
        // If there is already a DOM element with this id, Orejime will use it instead of creating a new element.
        // defaults to "orejime".
        elementID: "orejime",

        // Optional. For accessibility's sake, the Orejime modal must know what is the element
        // containing your app or website. Orejime should *not* be in this element.
        // The idea is your DOM could look like this after Orejime is initialized:
        // <body>
        //      <div id="orejime">...</div>
        //      <div id="app">your actual website</div>
        // </body>
        //
        // It is highly recommended to set this option, even though it's not required.
        // defaults to undefined.
        appElement: ".container",

        // Optional. You can set a custom expiration time for the Orejime cookie, in days.
        // defaults to 365.
        cookieExpiresAfterDays: 365,

        // Optional. You can provide a custom function to serialize the cookie contents.
        stringifyCookie: (contents) => JSON.stringify(contents),

        // Optional. You can provide a custom function to unserialize the cookie contents.
        parseCookie: (cookie) => JSON.parse(cookie),

        // You must provide a link to your privacy policy page
        privacyPolicy: "https://whyarchitecture.eu/privacy-policy",

        // Optional. Applications configured below will be ON by default if default=true.
        // defaults to true
        default: true,

        // Optional. If "mustConsent" is set to true, Orejime will directly display the consent
        // manager modal and not allow the user to close it before having actively
        // consented or declined the use of third-party apps.
        // defaults to false
        mustConsent: false,

        // Optional. If "mustNotice" is set to true, Orejime will display the consent
        // notice and not allow the user to close it before having actively
        // consented or declined the use of third-party apps.
        // Has no effect if mustConsent is set to true.
        // defaults to false
        mustNotice: false,

        // Optional. You can define the UI language directly here. If undefined, Orejime will
        // use the value given in the global "lang" variable, or fallback to the value
        // in the <html> lang attribute, or fallback to "en".
        lang: "en",

        // Optional. You can pass an image url to show in the notice.
        // If the image is not exclusively decorative, you can pass an object
        // with the image src and alt attributes: `logo: {src: '...', alt: '...'}`
        // defaults to false
        logo: false,

        // Optional. Set Orejime in debug mode to have a few stuff
        // logged in the console, like warning about missing translations.
        // defaults to false
        debug: false,

        // You can overwrite existing translations and add translations for your
        // app descriptions and purposes. See `src/translations.yml` for a full
        // list of translations that can be overwritten
        translations: {
            en: {
                consentModal: {
                    description: "We use cookies on this website to analyse web traffic.",
                },
                inlineTracker: {
                    description: "Example of an inline tracking script",
                },
                externalTracker: {
                    description: "Example of an external tracking script",
                },
                purposes: {
                    analytics: "Analytics",
                    security: "Security"
                },
                categories: {
                    analytics: {
                        description: "A long form description of the category."
                    }
                }
            },
        },

        // The list of third-party apps that Orejime will manage for you.
        // The apps will appear in the modal in the same order as defined here.
        apps: [
            {
                // The name of the app, used internally by Orejime.
                // Each name should match a name of a <script> tag defined in the
                // "Changing your existing third-party scripts" documentation step.
                name: "google-tag-manager",

                // The title of you app as listed in the consent modal.
                title: "Google Tag Manager",

                // A list of regex expressions, strings, or arrays, giving the names of
                // cookies set by this app. If the user withdraws consent for a
                // given app, Orejime will then automatically delete all matching
                // cookies.
                //
                // See a different example below with the inline-tracker app
                // to see how to define cookies set on different path or domains.
                cookies: [
                    "_ga",
                    "_gat",
                    "_gid",
                    "__utma",
                    "__utmb",
                    "__utmc",
                    "__utmt",
                    "__utmz"
                ],

                // Optional. The purpose(s) of this app. Will be listed on the consent notice.
                // Do not forget to add translations for all purposes you list here.
                purposes: ["analytics"],

                // Optional. A callback function that will be called each time
                // the consent state for the app changes. Passes
                // the `app` config as the second parameter as well.
                callback: function(consent, app){
                    // This is an example callback function.
                    //console.log("User consent for app " + app.name + ": consent=" + consent)
                },

                // Optional. If "required" is set to true, Orejime will not allow this app to
                // be disabled by the user.
                // default to false
                required: false,

                // Optional. If "optOut" is set to true, Orejime will load this app even before
                // the user gave explicit consent.We recommend always leaving this "false".
                // defaults to false
                optOut: false,

                // Optional. If "default" is set to true, the app will be enabled by default
                // Overwrites the global "default" setting.
                // defaults to the value of the gobal "default" setting
                default: true,

                // Optional. If "onlyOnce" is set to true, the app will only be executed
                // once regardless how often the user toggles it on and off.
                // defaults to false
                onlyOnce: true,
            }
        ]/*,
        // Optional. A list of categories under which apps will be classified.
        // This allows for a visual grouping of the different apps, along with a
        // description of their purpose.
        categories: {
            [
                "name": "analytics",
                "title": "Analytics",
                // The list of apps belonging to the category, referenced by name.
                "apps": [
                    "google-tag-manager"
                ]
            ]
        }*/
    }

    Orejime.init(orejimeConfig);

});
